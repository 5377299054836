import { Button } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useState } from "react";

export default function Layout({ children }) {
  const router = useRouter();
  const { t, i18n } = useTranslation(["common"]);

  const [langs, setLangs] = useState([
    {
      code: "en",
      name: "English",
    },
    {
      code: "ku",
      name: "کوردی",
    },
    {
      code: "ar",
      name: "عربی",
    },
  ]);

  const changeLang = (code) => {
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      undefined,
      { locale: code }
    );

    localStorage.setItem("lang", code);
  };

  return (
    <div className="max-w-[1100px] mx-auto px-5 relative flex flex-col min-h-screen h-full">
      <div className="mt-10 shrink-0 w-full flex items-center justify-between">
        <Link className="" href={"/"}>
          <div className="mr-auto">
            <img className="w-[200px] " src="/logo.svg" alt="FileDoge" />
          </div>
        </Link>

        <div className="flex">
          <div className="mx-5">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: `<a href="https://www.buymeacoffee.com/sarkardev"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=sarkardev&button_colour=FFDD00&font_colour=000000&font_family=Lato&outline_colour=000000&coffee_colour=ffffff" /></a>`,
              }}
            ></div>
          </div>

          <div className="flex items-center gap-3">
            {router.pathname !== "/" && (
              <div className="hidden md:block">
                <Link href="/">
                  <a>
                    <Button
                      minWidth={150}
                      backgroundColor={"#BA9F33"}
                      _hover={{ backgroundColor: "#BA9A10" }}
                      _active={{ backgroundColor: "#DA9A10" }}
                    >
                      {t("uploadCta")}
                    </Button>
                  </a>
                </Link>
              </div>
            )}

            <div className="!color-[unset]">
              <Menu colorScheme={"blackAlpha"} size={100}>
                <MenuButton>
                  <div className="text-white">
                    {langs.find((e) => e.code == router.locale).name}
                  </div>
                </MenuButton>
                <MenuList
                  borderColor={"#404040"}
                  backgroundColor={"#202020"}
                  color={"#FBFBFB"}
                >
                  {langs.map((lang) => (
                    <MenuItem
                      key={lang.code}
                      _hover={{ backgroundColor: "#232323" }}
                      _active={{ backgroundColor: "#232323" }}
                      _focus={{ backgroundColor: "#232323" }}
                      onClick={(_) => changeLang(lang.code)}
                    >
                      <div className="flex items-center">
                        <div className="ml-3">{lang.name}</div>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[360px] text-center mx-auto my-10 bg-yellow-600/70 p-4 rounded-lg text-lg">
        This website is available for sale.{" "}
        <a href="mailto:info@filedoge.com" className="underline">
          Contact us
        </a>{" "}
        for more information.
      </div>
      <div className="my-auto shrink-0">{children}</div>
      <div className="shrink-0 py-5 flex items-center justify-between">
        <div
          dangerouslySetInnerHTML={{ __html: t("footerName") }}
          className="text-[#BA9F33]"
        ></div>
        <div className="flex gap-4">
          <a href="mailto:support@filedoge.com">{t("footerContact")}</a>
          <Link href="/faqs">
            <a>{t("footerFaq")}</a>
          </Link>
          <Link href="/rest_api">
            <a>{t("footerApi")}</a>
          </Link>
          <Link href="/terms">
            <a>{t("terms")}</a>
          </Link>
          <Link href="/privacy">
            <a>{t("privacy")}</a>
          </Link>
          <Link href="/dmca">
            <a>{t("dmca")}</a>
          </Link>
        </div>
      </div>
    </div>
  );
}
