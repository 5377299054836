import { ChakraProvider } from "@chakra-ui/react";
import "../styles/global.css";
import "../styles/style.scss";
import { appWithTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Head from "next/head";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [currentPath, setCurrentPath] = useState(router.pathname);

  useEffect(() => {
    const dir = ["en"].includes(router.locale) ? "ltr" : "rtl";
    document.body.dir = dir;
  }, [router.locale]);

  useEffect(() => {
    setCurrentPath(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    if (!router.isReady) return;

    const lang = localStorage.getItem("lang");
    if (lang) {
      router.push(
        {
          pathname: router.pathname,
          query: router.query,
        },
        undefined,
        { locale: lang }
      );
    }
  }, [router.isReady]);

  return (
    <ChakraProvider>
      <Head>
        <title>File Doge</title>

        <link rel="icon" type="image/png" href="/favicon.png" />

        <meta name="title" content="File Doge" />
        <meta
          name="description"
          content="Upload unlimited files anonymously without sing up"
        />

        <meta property="og:type" content="website/" />
        <meta property="og:url" content="http://filedog.com/" />
        <meta property="og:title" content="File Doge" />
        <meta
          property="og:description"
          content="Upload unlimited files anonymously without sing up"
        />
        <meta property="og:image" content="/seo.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://filedog.com/" />
        <meta property="twitter:title" content="File Doge" />
        <meta
          property="twitter:description"
          content="Upload unlimited files anonymously without sing up"
        />
        <meta property="twitter:image" content="/seo.png" />
      </Head>

      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ChakraProvider>
  );
}

export default appWithTranslation(MyApp);
